import { createApp } from "vue";
import PrimeVue from "primevue/config";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/mdc-dark-deeppurple/theme.css";
import "primeicons/primeicons.css";
import "./assets/base.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

const app = createApp(App);
app.use(PrimeVue);
app.use(store);
app.use(router);
app.mount("#app");

axios.defaults.baseURL = "https://api." + window.location.hostname;
console.log("LDAP API:", axios.defaults.baseURL);
