import axios from 'axios';

export default class GroupService {

    auth: {username: string, password: string};

    constructor(auth: {username: string, password: string}) {
        this.auth = auth;
    }

    getGroups() {
        console.log(`[API] Getting all groups`);
        return new Promise<unknown>((resolve, reject) => {
            axios.post(
                "group/list",
                null,
                {
                    auth: this.auth
                }
            ).then((response) => {
                console.log(response);
                if(response.status === 200 && response.data.success) {
                    resolve(
                        response.data.groups.map((
                            groupDetails: { name: any, description: any }
                        ) => ({
                        name: groupDetails.name,
                        description: groupDetails.description,
                      }))
                      .filter((group: any) => group.name !== "user-any")
                    );
                } else {
                    reject(response.data);
                }
            }).catch((reason) => {
                reject({
                    error_message: "Network error - " + reason
                });
            });
        });
    }

    getGroupsForUser(username: string) {
        console.log(`[API] Getting groups for user ${username}`);
        return new Promise<unknown>((resolve, reject) => {
            axios.post(
                "group/list",
                {
                    username: username
                },
                {
                    auth: this.auth
                }
            ).then((response) => {
                console.log(response);
                if(response.status === 200 && response.data.success) {
                    resolve(
                        response.data.groups.map((groupName: any) => {
                            return {
                                name: groupName
                            };
                        })
                      .filter((group: any) => group.name !== "user-any")
                    );
                } else {
                    reject(response.data);
                }
            }).catch((reason) => {
                reject({
                    error_message: "Network error - " + reason
                });
            });
        });
    }

    createGroup(name: string, description: string) {
        console.log(`[API] Creating group ${name}`);
        return new Promise<unknown>((resolve, reject) => {
            axios.post(
                "group/create",
                {
                    name: name,
                    description: description
                },
                {
                    auth: this.auth
                }
            ).then((response) => {
                console.log(response);
                if (response.status === 200 && response.data.success) {
                    resolve(response.data);
                } else {
                    const message = 'details' in response.data ? response.data.details : response.data.error_message;
                    reject({
                        error_message: message
                    });
                }
            }).catch((reason) => {
                console.log(reason);
                reject({
                    error_message: "Network error - " + reason
                });
            });
        });
    }

    deleteGroup(name: string) {
        console.log(`[API] Deleting group ${name}`);
        return new Promise<unknown>((resolve, reject) => {
            axios.request(
                {
                    url: "group/delete",
                    method: "DELETE",
                    data: {
                        name: name
                    },
                    auth: this.auth,
                }
            ).then((response) => {
                console.log(response);
                if(response.status === 200 && response.data.success) {
                    resolve(response.data);
                } else {
                    const message = 'details' in response.data ? response.data.details : response.data.error_message;
                    reject({
                        error_message: message
                    });
                }
            }).catch((reason) => {
                reject({
                    error_message: "Network error - " + reason
                });
            })
        })
    }

    updateGroup(name: string, description: string) {
        console.log(`[API] Changing description of group ${name}`);
        return new Promise<any>((resolve, reject) => {
            axios.post(
                "group/update",
                {
                    name: name,
                    description: description
                },
                {
                    auth: this.auth
                }
            ).then((response) => {
                console.log(response);
                if(response.status === 200 && response.data.success) {
                    resolve(response.data);
                } else {
                    const message = 'details' in response.data ? response.data.details : response.data.error_message;
                    reject({
                        error_message: message
                    });
                }
            }).catch((reason) => {
                reject({
                    error_message: "Network error - " + reason
                });
            });
        })
    }

}
