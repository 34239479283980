<template>
  <Menubar :model="menuItems" />
  <router-view />
</template>

<script lang="ts">
import { computed, ref } from "vue";
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "vuex";
import Menubar from "primevue/menubar";

export default defineComponent({
  components: {
    Menubar,
  },

  setup() {
    const store = useStore();

    const menuItems = computed(() => {
      const accessLevel = store.state.userAccessLevel;
      const result: unknown[] = [];
      if (accessLevel === "admin") {
        result.push({
          label: "Users",
          icon: "pi pi-fw pi-user",
          to: "/user-list",
        });
        result.push({
          label: "Groups",
          icon: "pi pi-fw pi-sitemap",
          to: "/group-list",
        });
      }
      if (accessLevel !== null) {
        result.push({
          label: "Change password",
          icon: "pi pi-fw pi-unlock",
          to: "/change-password",
        });
      }
      return result;
    });

    return { menuItems };
  },
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
