<template>
  <div>
    <div class="card">
      <DataTable
        :value="groups"
        responsiveLayout="scroll"
        breakpoint="960px"
        dataKey="username"
        :loading="loading"
        v-model:filters="filters"
        :rowHover="true"
        filterDisplay="menu"
        :globalFilterFields="['name']"
      >
        <template #header>
          <div class="flex-boi-header">
            <span>Groups</span>
            <Button
              type="button"
              icon="pi pi-sitemap"
              class="p-button-outlined button-margin-left"
              label="New group"
              @click="openNewGroupDialog"
            />
          </div>
        </template>
        <template #empty> No groups found </template>
        <template #loading> Loading groups. Please wait. </template>
        <Column
          field="name"
          header="Name"
          sortable
          filterMatchMode="contains"
          style="min-width: 14rem"
        >
          <template #body="{ data }">
            {{ data.name }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by name"
            />
          </template>
        </Column>
        <Column
          field="description"
          header="Description"
          sortable
          style="min-width: 14rem"
        >
          <template #body="{ data }">
            {{ data.description }}
          </template>
        </Column>
        <Column
          headerStyle="text-align: center"
          bodyStyle="text-align: right; overflow: visible"
        >
          <template #body="{ data }">
            <Button
              type="button"
              icon="pi pi-pencil"
              class="p-button-help p-button-outlined"
              label="Change description"
              @click="changeDescription(data.name, null)"
            ></Button>
            <Button
              type="button"
              icon="pi pi-ban"
              class="p-button-danger p-button-outlined"
              label="Delete group"
              @click="deleteGroup(data.name, false)"
            ></Button>
          </template>
        </Column>
      </DataTable>
    </div>
    <div>
      <Dialog
        header="New group"
        v-model:visible="displayNewGroupDialog"
        :breakpoints="{ '960px': '75vw' }"
        :style="{ width: '50vw' }"
        :model="true"
        :closable="false"
      >
        <div>
          <Message severity="error" :life="5000" v-if="isNewGroupErrorShown"
            ><span>{{ newGroupErrorMessage }}</span>
          </Message>
          <h5>Name</h5>
          <InputText type="text" v-model="newGroupName" placeholder="Name" />
          <h5>Description</h5>
          <InputText
            type="text"
            v-model="newGroupDescription"
            placeholder="Description"
          />
        </div>
        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            @click="closeNewGroupDialog"
            class="p-button-text"
          />
          <Button
            label="Create group"
            icon="pi pi-check"
            @click="confirmNewGroupDialog"
            autofocus
          />
        </template>
      </Dialog>
    </div>
    <div>
      <Dialog
        header="Delete group"
        v-model:visible="displayDeleteGroupConfirmationDialog"
        :breakpoints="{ '960px': '75vw' }"
        :style="{ width: '50vw' }"
        :modal="true"
        :closable="true"
      >
        <div>
          <Message severity="error" :life="5000" v-if="isDeleteGroupErrorShown"
            ><span>{{ deleteGroupErrorMessage }}</span>
          </Message>
        </div>
        <h5>Are you sure you want to delete {{ deleteGroupName }}?</h5>
        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            @click="closeDeleteGroupDialog"
            class="p-button-text"
          />
          <Button
            label="Delete group"
            icon="pi pi-block"
            @click="confirmDeleteGroupDialog"
            autofocus
          />
        </template>
      </Dialog>
    </div>
    <div>
      <Dialog
        header="Change group description"
        v-model:visible="displayChangeDescriptionDialog"
        :breakpoints="{ '960px': '75vw' }"
        :style="{ width: '50vw' }"
        :modal="true"
        :closable="true"
      >
        <div>
          <Message
            severity="error"
            :life="5000"
            v-if="isChangeDescriptionErrorShown"
            ><span>{{ changeDescriptionErrorMessage }}</span>
          </Message>
        </div>
        <h5>Change description of {{ changeDescriptionName }}</h5>
        <InputText
          type="text"
          v-model="changeDescriptionDescription"
          placeholder="New description"
        />
        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            @click="closeChangeDescriptionDialog"
            class="p-button-text"
          />
          <Button
            label="Change description"
            icon="pi pi-block"
            @click="confirmChangeDescriptionDialog"
            autofocus
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "@vue/runtime-core";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Message from "primevue/message";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import GroupService from "../services/GroupService";
import { useStore } from "vuex";

export default defineComponent({
  name: "GroupList",
  components: {
    DataTable,
    InputText,
    Column,
    Button,
    Dialog,
    Message,
  },

  setup() {
    onMounted(() => {
      loadGroups();
    });

    const store = useStore();
    const groups = ref<any[]>([]);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    const auth = {
      username: store.state.username,
      password: store.state.password,
    };
    const groupService = ref(new GroupService(auth));
    const loading = ref(true);

    const displayNewGroupDialog = ref(false);
    const isNewGroupErrorShown = ref(false);
    const newGroupErrorMessage = ref("");
    const newGroupName = ref("");
    const newGroupDescription = ref("");
    const openNewGroupDialog = () => {
      displayNewGroupDialog.value = true;
    };
    const closeNewGroupDialog = () => {
      displayNewGroupDialog.value = false;
      newGroupName.value = "";
      newGroupDescription.value = "";
      isNewGroupErrorShown.value = false;
    };
    const confirmNewGroupDialog = () => {
      const name = newGroupName.value;
      const description = newGroupDescription.value;
      console.log(`Creating group: ${name} (${description})`);
      groupService.value
        .createGroup(name, description)
        .then((response: any) => {
          console.log(response);
          closeNewGroupDialog();
          loadGroups();
        })
        .catch((reason: any) => {
          newGroupErrorMessage.value = reason.error_message;
          isNewGroupErrorShown.value = true;
        });
    };

    const displayDeleteGroupConfirmationDialog = ref(false);
    const isDeleteGroupErrorShown = ref(false);
    const deleteGroupErrorMessage = ref("");
    const deleteGroupName = ref("");
    const deleteGroup = (name: string, confirmed: boolean) => {
      console.log("Deleting group " + name);
      if (confirmed) {
        groupService.value
          .deleteGroup(name)
          .then(() => {
            closeDeleteGroupDialog();
            deleteGroupName.value = "";
            loadGroups();
          })
          .catch(({ error_message }) => {
            isDeleteGroupErrorShown.value = false;
            isDeleteGroupErrorShown.value = true;
            deleteGroupErrorMessage.value = error_message;
          });
      } else {
        displayDeleteGroupConfirmationDialog.value = true;
        deleteGroupName.value = name;
      }
    };
    const closeDeleteGroupDialog = () => {
      displayDeleteGroupConfirmationDialog.value = false;
    };
    const confirmDeleteGroupDialog = () => {
      deleteGroup(deleteGroupName.value, true);
    };

    const displayChangeDescriptionDialog = ref(false);
    const isChangeDescriptionErrorShown = ref(false);
    const changeDescriptionErrorMessage = ref("");
    const changeDescriptionName = ref("");
    const changeDescriptionDescription = ref("");
    const changeDescription = (name: string, description: string) => {
      if(description !== null) {
        groupService.value.updateGroup(name, description)
          .then(() => {
            isChangeDescriptionErrorShown.value = false;
            displayChangeDescriptionDialog.value = false;
            changeDescriptionName.value = "";
            changeDescriptionDescription.value = "";
            loadGroups();
          })
          .catch(({ error_message }) => {
            console.error(error_message);
            isChangeDescriptionErrorShown.value = true;
            changeDescriptionErrorMessage.value = error_message;
          });
      } else {
        displayChangeDescriptionDialog.value = true;
        changeDescriptionName.value = name;
        for (const group of groups.value) {
          if (group.name === name) {
            changeDescriptionDescription.value = group.description;
          }
        }
        isChangeDescriptionErrorShown.value = false;
      }
    };
    const closeChangeDescriptionDialog = () => {
      displayChangeDescriptionDialog.value = false;
    };
    const confirmChangeDescriptionDialog = () => {
      changeDescription(
        changeDescriptionName.value,
        changeDescriptionDescription.value
      );
    };


    const loadGroups = () => {
      groupService.value.getGroups().then((data: any) => {
        groups.value = data;
        loading.value = false;
      });
    };

    return {
      groups,
      filters,
      loading,
      openNewGroupDialog,
      displayNewGroupDialog,
      isNewGroupErrorShown,
      newGroupErrorMessage,
      newGroupName,
      newGroupDescription,
      closeNewGroupDialog,
      confirmNewGroupDialog,

      deleteGroup,
      displayDeleteGroupConfirmationDialog,
      isDeleteGroupErrorShown,
      deleteGroupErrorMessage,
      deleteGroupName,
      closeDeleteGroupDialog,
      confirmDeleteGroupDialog,

      changeDescription,
      displayChangeDescriptionDialog,
      isChangeDescriptionErrorShown,
      changeDescriptionErrorMessage,
      changeDescriptionName,
      changeDescriptionDescription,
      closeChangeDescriptionDialog,
      confirmChangeDescriptionDialog,
    };
  }
});
</script>

<style scoped>
.button-margin-left {
  margin-left: auto;
}

.flex-boi-header {
  display: flex;
  align-items: center;
}

.p-multiselect {
  width: 100%;
}
</style>
