
import { defineComponent, ref, onMounted } from "@vue/runtime-core";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import MultiSelect from "primevue/multiselect";
import Message from "primevue/message";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import UserService from "../services/UserService";
import GroupService from "../services/GroupService";
import { useStore } from "vuex";

export default defineComponent({
  name: "UserList",
  components: {
    DataTable,
    InputText,
    Column,
    Button,
    Dialog,
    MultiSelect,
    Message,
  },

  setup() {
    onMounted(() => {
      loadUsers();
      groupService.value.getGroups().then((data: any) => {
        groups.value = data.map((group: any) => {
          return {
            name: group.name
          };
        });
      });
    });

    const store = useStore();
    const users = ref();
    const groups = ref<{name: string}[]>([]);
    const selectedUsers = ref();
    const newUserSelectedGroups = ref<{name: string}[]>([]);
    const newUserUsername = ref();
    const newUserName = ref();
    const newUserPassword = ref();
    const isNewUserErrorShown = ref(false);
    const newUserErrorMessage = ref("");
    const displayUserCreatedDialog = ref(false);
    const userCreatedMessage = ref("");
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      username: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    const auth = {
      username: store.state.username,
      password: store.state.password,
    };
    const userService = ref(new UserService(auth));
    const groupService = ref(new GroupService(auth));
    const loading = ref(true);

    const displayNewUserDialog = ref(false);
    const openNewUserDialog = () => {
      displayNewUserDialog.value = true;
    };
    const closeNewUserDialog = () => {
      displayNewUserDialog.value = false;
      newUserSelectedGroups.value = [];
      newUserUsername.value = "";
      newUserName.value = "";
      newUserPassword.value = "";
    };
    const newUserErrorShow = (message: string) => {
      newUserErrorMessage.value = message;
      isNewUserErrorShown.value = false;
      isNewUserErrorShown.value = true;
    };
    const finishNewUserDialog = (name: string, password: string) => {
      userCreatedMessage.value = "User '" + name + "', password: " + password;
      closeNewUserDialog();
      displayUserCreatedDialog.value = true;
      loadUsers();
    };
    const confirmNewUserDialog = () => {
      const username = newUserUsername.value;
      const name = newUserName.value;
      console.log("Creating user: " + username + ", name: " + name);
      userService.value
        .createUser(username, name)
        .then((response: any) => {
          console.log(response);
          const generatedPassword: string = response.password;

          // for each group, add new user to that group
          const groupCreatePromises: Promise<any>[] = [];
          console.log(newUserSelectedGroups.value);
          for (let selectedGroup of newUserSelectedGroups.value) {
            const groupName = selectedGroup.name;
            groupCreatePromises.push(
              userService.value.addUserToGroup(username, groupName)
            );
          }
          Promise.all(groupCreatePromises)
            .then(() => {
              finishNewUserDialog(name, generatedPassword);
            })
            .catch((reason: any) => {
              newUserErrorShow(reason.error_message);
            });
        })
        .catch((response) => {
          console.log(response);
          newUserErrorShow(response.error_message);
        });
    };

    const displayDeleteUserConfirmationDialog = ref(false);
    const isDeleteUserErrorShown = ref(false);
    const deleteUserErrorMessage = ref("");
    const deleteUserUsername = ref("");
    const deleteUser = (username: string, confirmed: boolean) => {
      console.log("Deleting user " + username);
      if (confirmed) {
        userService.value
          .deleteUser(username)
          .then(() => {
            closeDeleteUserDialog();
            deleteUserUsername.value = "";
            loadUsers();
          })
          .catch(({ error_message }) => {
            isDeleteUserErrorShown.value = false;
            isDeleteUserErrorShown.value = true;
            deleteUserErrorMessage.value = error_message;
          });
      } else {
        displayDeleteUserConfirmationDialog.value = true;
        deleteUserUsername.value = username;
      }
    };
    const closeDeleteUserDialog = () => {
      displayDeleteUserConfirmationDialog.value = false;
    };
    const confirmDeleteUserDialog = () => {
      deleteUser(deleteUserUsername.value, true);
    };

    const displayChangePasswordDialog = ref(false);
    const isChangePasswordErrorShown = ref(false);
    const changePasswordErrorMessage = ref("");
    const changePasswordUsername = ref("");
    const changePasswordPassword = ref("");
    const changePassword = (username: string, password: string) => {
      console.log("Changing password of user " + username);
      if (password !== null) {
        userService.value
          .changePassword(username, password)
          .then(() => {
            closeChangePasswordDialog();
            changePasswordUsername.value = "";
            changePasswordPassword.value = "";
            loadUsers();
          })
          .catch(({ error_message }) => {
            isChangePasswordErrorShown.value = false;
            isChangePasswordErrorShown.value = true;
            changePasswordErrorMessage.value = error_message;
          });
      } else {
        displayChangePasswordDialog.value = true;
        changePasswordUsername.value = username;
      }
    };
    const closeChangePasswordDialog = () => {
      displayChangePasswordDialog.value = false;
    };
    const confirmChangePasswordDialog = () => {
      changePassword(
        changePasswordUsername.value,
        changePasswordPassword.value
      );
    };

    const displayChangeGroupsDialog = ref(false);
    const isChangeGroupsErrorShown = ref(false);
    const changeGroupsErrorMessage = ref("");
    const changeGroupsUsername = ref("");
    const changeGroupsOriginalGroups = ref<{name: string}[]>([]);
    const changeGroupsGroups = ref<{name: string}[]>([]);
    const changeGroupsSelectDisabled = ref(true);
    const changeGroups = (username: string, original: {name: string}[], target: {name: string}[]) => {
      console.log("changeGroups invoked");
      console.log({
        username: username,
        original: original,
        target: target
      });
      if(original !== null && target !== null) {
        console.log("original and target aren't null, changing groups");
        const originalGroups = changeGroupsOriginalGroups.value.map((group) => group.name);
        console.log("originalGroups:");
        console.log(originalGroups);
        const targetGroups = changeGroupsGroups.value.map((group) => group.name);
        console.log("targetGroups:");
        console.log(targetGroups);
        const allGroups = [...originalGroups, ...targetGroups].filter((item, i, ar) => ar.indexOf(item) === i);
        console.log("allGroups:");
        console.log(allGroups);
        const allGroupChangePromises = [];
        for (const groupName of allGroups) {
          if(originalGroups.includes(groupName) && !targetGroups.includes(groupName)) {
            console.log(`${groupName} in originalGroups but not in targetGroups, removing`);
            // remove from group
            allGroupChangePromises.push(
              userService.value.removeUserFromGroup(username, groupName)
            );
          } else if (!originalGroups.includes(groupName) && targetGroups.includes(groupName)) {
            console.log(`${groupName} in targetGroups but not in originalGroups, adding`);
            // add to group
            allGroupChangePromises.push(
              userService.value.addUserToGroup(username, groupName)
            );
          } else {
            console.log(`${groupName} unchanged`);
          }
        }
        Promise.all(allGroupChangePromises)
          .then(() => {
            console.log("Groups changed");
            isChangeGroupsErrorShown.value = false;
            displayChangeGroupsDialog.value = false;
            changeGroupsUsername.value = "";
            changeGroupsGroups.value = [];
            changeGroupsOriginalGroups.value = [];
            changeGroupsSelectDisabled.value = true;
            loadUsers();
          })
          .catch(({ error_message }) => {
            console.error(error_message);
            isChangeGroupsErrorShown.value = true;
            changeGroupsErrorMessage.value = error_message;
          });
      } else {
        displayChangeGroupsDialog.value = true;
        changeGroupsUsername.value = username;
        changeGroupsGroups.value = [];
        changeGroupsOriginalGroups.value = [];
        changeGroupsSelectDisabled.value = true;

        groupService.value
          .getGroupsForUser(username)
          .then((userGroups: any) => {
            changeGroupsOriginalGroups.value = userGroups;
            console.log([...changeGroupsOriginalGroups.value]);
            changeGroupsGroups.value = [...changeGroupsOriginalGroups.value];
            changeGroupsSelectDisabled.value = false;
          })
          .catch(({ error_message }) => {
            isChangeGroupsErrorShown.value = true;
            changeGroupsErrorMessage.value = error_message;
          });
      }
    };
    const closeChangeGroupsDialog = () => {
      displayChangeGroupsDialog.value = false;
    };
    const confirmChangeGroupsDialog = () => {
      changeGroups(
        changeGroupsUsername.value,
        changeGroupsOriginalGroups.value,
        changeGroupsGroups.value
      );
    };

    const loadUsers = () => {
      userService.value.getUsers().then((data: unknown) => {
        users.value = data;
        loading.value = false;
      });
    };

    return {
      users,
      selectedUsers,
      filters,
      loading,
      displayNewUserDialog,
      openNewUserDialog,
      closeNewUserDialog,
      groups,
      newUserSelectedGroups,
      newUserUsername,
      newUserName,
      newUserPassword,
      isNewUserErrorShown,
      newUserErrorMessage,
      displayUserCreatedDialog,
      userCreatedMessage,
      confirmNewUserDialog,
      deleteUser,
      displayDeleteUserConfirmationDialog,
      isDeleteUserErrorShown,
      deleteUserErrorMessage,
      deleteUserUsername,
      closeDeleteUserDialog,
      confirmDeleteUserDialog,
      displayChangePasswordDialog,
      isChangePasswordErrorShown,
      changePasswordErrorMessage,
      changePasswordUsername,
      changePasswordPassword,
      changePassword,
      closeChangePasswordDialog,
      confirmChangePasswordDialog,
      displayChangeGroupsDialog,
      isChangeGroupsErrorShown,
      changeGroupsErrorMessage,
      changeGroupsUsername,
      changeGroupsOriginalGroups,
      changeGroupsGroups,
      changeGroupsSelectDisabled,
      changeGroups,
      closeChangeGroupsDialog,
      confirmChangeGroupsDialog,
    };
  },
});
