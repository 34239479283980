import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import UserList from "../views/UserList.vue";
import GroupList from "../views/GroupList.vue";
import ChangePassword from "../views/ChangePassword.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/user-list",
    name: "Users",
    component: UserList
  },
  {
    path: "/group-list",
    name: "Groups",
    component: GroupList
  },
  {
    path: "/change-password",
    name: "Change password",
    component: ChangePassword
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
router.beforeEach((to, _, next) => {
  if (to.name !== "Home" && !store.state.isUserLoggedIn) {
    next("/");
  } else {
    next();
  }
});

export default router;
