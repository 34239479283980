<template>
  <div>
    <Card class="login-card">
      <template #title> Change own password </template>
      <template #content>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-12 p-md-4">
            <Password
              id="password"
              v-model="password"
              :feedback="true"
              placeholder="New password"
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <Message severity="error" :life="3000" v-if="isErrorShown">
              <span>{{ errorMessage }}</span>
            </Message>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <Message severity="success" :life="3000" v-if="isSuccessShown">
              <span>Password changed</span>
            </Message>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <Button label="Change password" @click="changePassword" />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import UserService from "../services/UserService";
import Password from "primevue/password";
import Card from "primevue/card";
import Button from "primevue/button";
import Message from "primevue/message";

export default defineComponent({
  name: "Change password",
  components: {
    Password,
    Card,
    Button,
    Message,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const auth = {
      username: store.state.username,
      password: store.state.password,
    };
    const userService = ref(new UserService(auth));

    const password = ref("");
    const isErrorShown = ref(false);
    const errorMessage = ref(false);
    const isSuccessShown = ref(false);

    const changePassword = () => {
      isSuccessShown.value = false;
      isErrorShown.value = false;
      userService.value
        .selfChangePassword(password.value)
        .then(() => {
          isSuccessShown.value = true;
          store.commit("setUserCredentials", {
            username: auth.username,
            password: password.value,
          });
          auth.password = password.value;
        })
        .catch((reason) => {
          errorMessage.value = reason.error_message;
          isErrorShown.value = true;
        });
    };

    return {
      password,
      isErrorShown,
      errorMessage,
      isSuccessShown,
      changePassword,
    };
  },
});
</script>

<style scoped>
.login-card {
  max-width: 50%;
  margin: 50px auto;
}
</style>
