import axios from 'axios';

export default class UserServie {

    auth: {username: string, password: string};

    constructor(auth: {username: string, password: string}) {
        this.auth = auth;
    }

    getUsers() {
        console.log(`[API] Getting all users`);
        return new Promise<unknown>((resolve, reject) => {
            axios.post(
                "user/list",
                null,
                {
                    auth: this.auth
                }
            ).then((response) => {
                console.log(response);
                if(response.status === 200 && response.data.success) {
                    const usersMapped = response.data.users.map((user: any) => {
                        const username = user.username.split(",")[0].toLowerCase().slice(3);
                        return {
                            username: username,
                            name: user.name
                        }
                    })
                    resolve(usersMapped);
                } else {
                    reject(response.data);
                }
            })
        });
    }

    createUser(username: string, name: string) {
        console.log(`[API] Creating user ${username}`);
        return new Promise<unknown>((resolve, reject) => {
            axios.post(
                "user/create",
                {
                    username: username,
                    name: name
                },
                {
                    auth: this.auth
                }
            ).then((response) => {
                console.log(response);
                if(response.status === 200 && response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            }).catch((reason) => {
                console.log(reason);
                reject({
                    error_message: "Network error - " + reason
                });
            });
        });
    }

    deleteUser(username: string) {
        console.log(`[API] Deleting user ${username}`);
        return new Promise<unknown>((resolve, reject) => {
            axios.request(
                {
                    url: "user/delete",
                    method: "DELETE",
                    data: {
                        username: username
                    },
                    auth: this.auth,
                }
            ).then((response) => {
                console.log(response);
                if(response.status === 200 && response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            }).catch((reason) => {
                console.log(reason);
                reject({
                    error_message: "Network error - " + reason
                });
            });
        });
    }

    addUserToGroup(username: string, group: string) {
        console.log(`[API] Adding user ${username} to group ${group}`);
        return new Promise<any>((resolve, reject) => {
            axios.post(
                "user/add_to_group",
                {
                    username: username,
                    group: group
                },
                {
                    auth: this.auth
                }
            ).then((response) => {
                console.log(response);
                if(response.status === 200 && response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            }).catch((reason) => {
                reject({
                    error_message: "Network error - " + reason
                });
            });
        });
    }

    removeUserFromGroup(username: string, group: string) {
        console.log(`[API] Removing user ${username} from group ${group}`);
        return new Promise<any>((resolve, reject) => {
            axios.request(
                {
                    url: "user/remove_from_group",
                    method: "DELETE",
                    data: {
                        username: username,
                        group: group
                    },
                    auth: this.auth,
                }
            ).then((response) => {
                console.log(response);
                if(response.status === 200 && response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            }).catch((reason) => {
                console.log(reason);
                reject({
                    error_message: "Network error - " + reason
                });
            });
        });
    }

    changePassword(username: string, password: string) {
        console.log(`[API] Changing password of user ${username}`);
        return new Promise<any>((resolve, reject) => {
            axios.post(
                "user/change_password",
                {
                    username: username,
                    password: password
                },
                {
                    auth: this.auth
                }
            ).then((response) => {
                console.log(response);
                if(response.status === 200 && response.data.success) {
                    resolve(response.data);
                } else {
                    const message = 'details' in response.data ? response.data.details : response.data.error_message;
                    reject({
                        error_message: message
                    });
                }
            }).catch((reason) => {
                reject({
                    error_message: "Network error - " + reason
                });
            });
        });
    }

    selfChangePassword(new_password: string) {
        console.log(`[API] Changing own password`);
        return new Promise<any>((resolve, reject) => {
            axios.post(
                "user/self_change_password",
                {
                    "username": this.auth.username,
                    "old": this.auth.password,
                    "new": new_password
                },
                {
                    auth: this.auth
                }
            ).then((response) => {
                console.log(response);
                if(response.status === 200 && response.data.success) {
                    resolve(response.data);
                } else {
                    const message = 'details' in response.data ? response.data.details : response.data.error_message;
                    reject({
                        error_message: message
                    });
                }
            }).catch((reason) => {
                reject({
                    error_message: "Network error - " + reason
                });
            });
        })
    }

}