
import { computed, ref } from "vue";
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "vuex";
import Menubar from "primevue/menubar";

export default defineComponent({
  components: {
    Menubar,
  },

  setup() {
    const store = useStore();

    const menuItems = computed(() => {
      const accessLevel = store.state.userAccessLevel;
      const result: unknown[] = [];
      if (accessLevel === "admin") {
        result.push({
          label: "Users",
          icon: "pi pi-fw pi-user",
          to: "/user-list",
        });
        result.push({
          label: "Groups",
          icon: "pi pi-fw pi-sitemap",
          to: "/group-list",
        });
      }
      if (accessLevel !== null) {
        result.push({
          label: "Change password",
          icon: "pi pi-fw pi-unlock",
          to: "/change-password",
        });
      }
      return result;
    });

    return { menuItems };
  },
});
