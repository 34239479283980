
import { defineComponent, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Password from "primevue/password";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Message from "primevue/message";

export default defineComponent({
  name: "Home",
  components: {
    Password,
    Card,
    InputText,
    Button,
    Message,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    onBeforeMount(() => {
      console.log("Would redirect here");
      window.addEventListener("keyup", (event) => {
        if (event.key == "Enter") {
          login();
        }
      });
      //router.push("/user-list");
    });

    if (store.state.userAccessLevel == "admin") {
      // redirect to user list
      router.push("/user-list");
    } else if (store.state.userAccessLevel == "basic") {
      // redirect to password change
      router.push("/change-password");
    }

    const username = ref<string>();
    const password = ref<string>();
    const isErrorShown = ref<boolean>(false);

    const login = async () => {
      await store.dispatch("getUserAccessLevel", {
        username: username.value,
        password: password.value,
      });
      if (store.state.isUserLoggedIn) {
        const accessLevel = store.state.userAccessLevel;
        if (accessLevel == "admin") {
          // redirect to user list
          router.push("/user-list");
        } else {
          // redirect to password change
          router.push("/password-change");
        }
        store.commit("setUserCredentials", {
          username: username,
          password: password,
        });
      } else {
        // show error incorrect credentials
        isErrorShown.value = true;
        setTimeout(() => {
          isErrorShown.value = false;
        }, 3000);
      }
    };

    return { username, password, login, isErrorShown };
  },
});
