import axios from "axios";
import { createStore } from "vuex";

export default createStore({
  state: {
    isUserLoggedIn: false,
    userAccessLevel: null,
    username: null,
    password: null
  },
  mutations: {
    setIsUserLoggedIn: (state, isLoggedIn) => {
      state.isUserLoggedIn = isLoggedIn;
    },
    setUserAccessLevel: (state, accessLevel) => {
      state.userAccessLevel = accessLevel;
    },
    setUserCredentials: (state, { username, password }) => {
      state.username = username;
      state.password = password;
    },
  },
  actions: {
    getUserAccessLevel: async ({commit}, {username, password}) => {
      return new Promise<void>((resolve, _) => {
        axios.post(
          "get_access_level",
          {
            "username": username
          },
          {
            "auth": {
              "username": username,
              "password": password
            }
          }
        ).then((response) => {
          console.log(response);
          if(response.status === 200 && response.data.success) {
            commit("setIsUserLoggedIn", true);
            commit("setUserAccessLevel", response.data.access_level);
          } else {
            commit("setIsUserLoggedIn", false);
          }
        }).catch(() => {
          commit("setIsUserLoggedIn", false);
        }).finally(() => {
          resolve();
        });
      })
    }
  },
  modules: {},
});
