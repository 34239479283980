
import { defineComponent, ref, onMounted } from "@vue/runtime-core";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Message from "primevue/message";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import GroupService from "../services/GroupService";
import { useStore } from "vuex";

export default defineComponent({
  name: "GroupList",
  components: {
    DataTable,
    InputText,
    Column,
    Button,
    Dialog,
    Message,
  },

  setup() {
    onMounted(() => {
      loadGroups();
    });

    const store = useStore();
    const groups = ref<any[]>([]);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    const auth = {
      username: store.state.username,
      password: store.state.password,
    };
    const groupService = ref(new GroupService(auth));
    const loading = ref(true);

    const displayNewGroupDialog = ref(false);
    const isNewGroupErrorShown = ref(false);
    const newGroupErrorMessage = ref("");
    const newGroupName = ref("");
    const newGroupDescription = ref("");
    const openNewGroupDialog = () => {
      displayNewGroupDialog.value = true;
    };
    const closeNewGroupDialog = () => {
      displayNewGroupDialog.value = false;
      newGroupName.value = "";
      newGroupDescription.value = "";
      isNewGroupErrorShown.value = false;
    };
    const confirmNewGroupDialog = () => {
      const name = newGroupName.value;
      const description = newGroupDescription.value;
      console.log(`Creating group: ${name} (${description})`);
      groupService.value
        .createGroup(name, description)
        .then((response: any) => {
          console.log(response);
          closeNewGroupDialog();
          loadGroups();
        })
        .catch((reason: any) => {
          newGroupErrorMessage.value = reason.error_message;
          isNewGroupErrorShown.value = true;
        });
    };

    const displayDeleteGroupConfirmationDialog = ref(false);
    const isDeleteGroupErrorShown = ref(false);
    const deleteGroupErrorMessage = ref("");
    const deleteGroupName = ref("");
    const deleteGroup = (name: string, confirmed: boolean) => {
      console.log("Deleting group " + name);
      if (confirmed) {
        groupService.value
          .deleteGroup(name)
          .then(() => {
            closeDeleteGroupDialog();
            deleteGroupName.value = "";
            loadGroups();
          })
          .catch(({ error_message }) => {
            isDeleteGroupErrorShown.value = false;
            isDeleteGroupErrorShown.value = true;
            deleteGroupErrorMessage.value = error_message;
          });
      } else {
        displayDeleteGroupConfirmationDialog.value = true;
        deleteGroupName.value = name;
      }
    };
    const closeDeleteGroupDialog = () => {
      displayDeleteGroupConfirmationDialog.value = false;
    };
    const confirmDeleteGroupDialog = () => {
      deleteGroup(deleteGroupName.value, true);
    };

    const displayChangeDescriptionDialog = ref(false);
    const isChangeDescriptionErrorShown = ref(false);
    const changeDescriptionErrorMessage = ref("");
    const changeDescriptionName = ref("");
    const changeDescriptionDescription = ref("");
    const changeDescription = (name: string, description: string) => {
      if(description !== null) {
        groupService.value.updateGroup(name, description)
          .then(() => {
            isChangeDescriptionErrorShown.value = false;
            displayChangeDescriptionDialog.value = false;
            changeDescriptionName.value = "";
            changeDescriptionDescription.value = "";
            loadGroups();
          })
          .catch(({ error_message }) => {
            console.error(error_message);
            isChangeDescriptionErrorShown.value = true;
            changeDescriptionErrorMessage.value = error_message;
          });
      } else {
        displayChangeDescriptionDialog.value = true;
        changeDescriptionName.value = name;
        for (const group of groups.value) {
          if (group.name === name) {
            changeDescriptionDescription.value = group.description;
          }
        }
        isChangeDescriptionErrorShown.value = false;
      }
    };
    const closeChangeDescriptionDialog = () => {
      displayChangeDescriptionDialog.value = false;
    };
    const confirmChangeDescriptionDialog = () => {
      changeDescription(
        changeDescriptionName.value,
        changeDescriptionDescription.value
      );
    };


    const loadGroups = () => {
      groupService.value.getGroups().then((data: any) => {
        groups.value = data;
        loading.value = false;
      });
    };

    return {
      groups,
      filters,
      loading,
      openNewGroupDialog,
      displayNewGroupDialog,
      isNewGroupErrorShown,
      newGroupErrorMessage,
      newGroupName,
      newGroupDescription,
      closeNewGroupDialog,
      confirmNewGroupDialog,

      deleteGroup,
      displayDeleteGroupConfirmationDialog,
      isDeleteGroupErrorShown,
      deleteGroupErrorMessage,
      deleteGroupName,
      closeDeleteGroupDialog,
      confirmDeleteGroupDialog,

      changeDescription,
      displayChangeDescriptionDialog,
      isChangeDescriptionErrorShown,
      changeDescriptionErrorMessage,
      changeDescriptionName,
      changeDescriptionDescription,
      closeChangeDescriptionDialog,
      confirmChangeDescriptionDialog,
    };
  }
});
